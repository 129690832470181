!(function () {

    document.addEventListener("DOMContentLoaded", function () {
        let home_page = document.querySelector('.home');


        if (!home_page || home_page == null) return;

        initHomeMap();
    });
})();

function initHomeMap() {
    let mapContainer = document.querySelector('.home-map');

    if (!mapContainer || mapContainer.length === 0)
        return;

    let programs = getPrograms(); //Json des programmes

    displayProgramsOsmMapHome('programs-map-home', programs);

    //Activation de la carte programmes
    document.querySelector('[data-switch-map="programs"]').classList.add('active');;
}

function displayProgramsOsmMapHome(mapContainerId, programs) {
    let markers = [];
    let zoom = 14;

    //Coordonnées de la France pour le centrage par défaut
    const default_map_lat = 48.852969;
    const default_map_long = 2.349903;

    let map = L.map(mapContainerId).setView([default_map_lat, default_map_long], zoom); //Création de la carte
    map.scrollWheelZoom.disable(); //Désactivation du scroll à la molette sur la carte
    map.InvalidateSize;

    //Définition de la tuile
    L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}', {
        maxZoom: 18
    }).addTo(map);

    //#region Marqueur    
    let marker_icon_size = [30, 40];
    let marker_icon_anchor = [15, 20];
    let marker_popup_anchor = [-9, -50];

    programs.forEach(program => {
        let marker_icon_url = program.marker.src;
        let marker_latitude = program.location.latitude;
        let marker_longitude = program.location.longitude;

        //Si le marqueur est un code svg, il faut modifier l'url
        if (program.marker.type == 'svg') {
            let marker_svg_html = program.marker.src;
            marker_icon_url = encodeURI("data:image/svg+xml," + marker_svg_html).replace('#', '%23');
        }


        //Tailles personnalisées
        if (program.marker.size != undefined) {
            switch (program.marker.size) {
                case 'small':
                    marker_icon_size = [15, 20];
                    break;
                case 'medium':
                    marker_icon_size = [30, 40];
                    break;
                case 'large':
                    marker_icon_size = [50, 66.66];
                    break;
            }
        }

        let marker_icon = L.icon({
            iconUrl: marker_icon_url,
            iconSize: marker_icon_size,
            iconAnchor: marker_icon_anchor,
            popupAnchor: marker_popup_anchor,
        });

        let title = program.program_info.title.replace('&rsquo;', "'");

        if (program.program_info.town)
        {
            title += ' - ' + program.program_info.town;
        }

        let marker = L.marker([marker_latitude, marker_longitude], {
            icon: marker_icon,
            title: title
        });
        marker.bindPopup(getInfoWindow(program.program_info));
        marker.addTo(map);
        markers.push(marker);

        //Ajout du marqueur du point de vente si il est renseigné
        if (program.program_agency != undefined) {
            let agency_marker_icon = L.icon({
                iconUrl: program.program_agency.marker_src,
                iconSize: marker_icon_size,
                iconAnchor: marker_icon_anchor,
                popupAnchor: marker_popup_anchor
            });

            let agency_marker = L.marker([program.program_agency.lat, program.program_agency.lng], {
                icon: agency_marker_icon,
                title: program.program_agency.name.replace('&rsquo;', "'")
            });
            agency_marker.bindPopup(getAgencyInfoWindow(program.program_agency));
            agency_marker.addTo(map);
            markers.push(agency_marker);
        }
    });
    //#endregion

    if (markers && markers.length > 0)
    {
        let bounds = new L.featureGroup(markers);
        map.fitBounds(bounds.getBounds().pad(0.5));
    }

    map.invalidateSize();
}

//Génère l'infobulle du point de vente sur uen carte OpenStreetMap
function getInfoWindow(program_info) {

    if (program_info == undefined)
        return;

    let info_window = '<div class="map-info-window" style="--custom-color:' + program_info.color + '">';
    if (program_info.picture != null) {
        info_window += '<div class="map-info-window-picture"><img src="' + program_info.picture + '" alt="' + program_info.title + '" class="map-info-window-img" /></div>';
        info_window += '<div class="map-info-window-content">';
    } else {
        info_window += '<div class="map-info-window-content full">';
    }
    if (program_info.town != null || program_info.zipcode != null) {
        info_window += '<span class="map-info-window-town-wrapper">';

        if (program_info.town != null) {
            info_window += '<span class="map-info-window-town">' + program_info.town + '</span>';
        }

        if (program_info.zipcode != null && program_info.town != null) {
            info_window += '<span class="map-info-window-zipcode"> (' + program_info.zipcode.substr(0, 2) + ')</span>';
        } else if (program_info.zipcode != null) {
            info_window += '<span class="map-info-window-zipcode">' + program_info.zipcode + '</span>';
        }

        info_window += '</span>';
    }

    if (program_info.title != null) {
        if (program_info.link != null) {
            info_window += '<a href="' + program_info.link + '" class="map-info-window-title">' + program_info.title + '</a>';
        } else {
            info_window += '<span class="map-info-window-title">' + program_info.title + '</span>';
        }
    }

    if (program_info.price != null) {
        info_window += '<span class="map-info-window-price">' + program_info.price + '</span>';
    }

    if (program_info.typology != null) {
        info_window += '<span class="map-info-window-typology">' + program_info.typology + '</span>';
    }

    if (program_info.progress_state != null) {
        info_window += '<span class="map-info-window-progress-state">' + program_info.progress_state + '</span>';
    }

    info_window += '</div>';
    info_window += '</div>';

    return info_window;
}

//Récupère les informations des programmes affichés sur la carte
function getPrograms() {
    return program_map_home_vars.programs_infos;
}