import Glide from '@glidejs/glide';

!(function () {
    let home_page = document.querySelector('.home');

    if (!home_page || home_page == null) return;

    initHomeSlider();
    initProgramsSlider();
    initTestimoniesSlider();
})();

/**
 * Configure le slider de la home
 */
function initHomeSlider() {
    let slides = document.querySelector('.home-slider');

    if (!slides || slides == null || slides.length == 0) return;

    let autoplay = 5000;

    if (window.innerWidth < 768) {
        autoplay = false;
    }

    let options = {
        type: 'carousel',
        autoplay: autoplay,
        animationDuration: 2000,
        rewind: true,
        animationTimingFunc: 'ease-in-out',
        perView: 1,
        
    };

    mountSlider(slides, options);
}

/**
 * Configure le slider des programmes
 */
function initProgramsSlider() {
    let slides = document.querySelector('.home-program-thumbs');

    if (!slides || slides == null || slides.length == 0) return;

    let slidesPerView = 2;
    let gap = 0;
    let peek = {
        before: 250,
        after: 250,
    };

    if (window.innerWidth < 1025) {
        peek = 0;
        slidesPerView = 1;
    }

    let options = {
        type: 'carousel',
        autoplay: false,
        animationDuration: 1000,
        rewind: true,
        animationTimingFunc: 'ease-in-out',
        gap: gap,
        perView: slidesPerView,
        peek: peek,
    };

    mountSlider(slides, options);
}

/**
 * Configure le slider des témoignages
 */
function initTestimoniesSlider() {
    let slides = document.querySelector('.home-testimonies');

    if (!slides || slides == null || slides.length == 0) return;

    let slidesPerView = 1;
    let gap = 50;

    console.log('largeur écran', window.innerWidth);
 
    if (window.innerWidth > 800) {
        slidesPerView = 2;
    }
    
    let options = {
        type: 'carousel',
        autoplay: false,
        animationDuration: 1000,
        rewind: true,
        animationTimingFunc: 'ease-in-out',
        gap: gap,
        perView: slidesPerView,
    };

    mountSlider(slides, options);
}

/**
 * Initialise les sliders
 * @param {} sliders
 * @param {*} options
 */
function mountSlider(sliders, options) {
    let slider = new Glide(sliders, options);

    //Si le slider n'a qu'une slide, on le désactive
    slider.on('mount.before', function () {
        if (sliders.querySelectorAll('.glide__slide').length <= 1) {
            slider.update({
                rewind: false,
            });
            slider.disable();
        }
    });

    slider.mount();
}
