import AOS from 'aos';

document.addEventListener("DOMContentLoaded", function () {
    AOS.init();
    lettering(".txt-anim");
});

window.addEventListener('scroll', function () {
    setHeaderClassOnScroll();
    animation();
});

    /**
     * Détermine si un élément est visible ou non à l'écran
     */
    function isElementInViewport(el) {
      const rect = el.getBoundingClientRect();
      return (
          rect.top >= 10 &&
          rect.left >= 0 &&
          rect.bottom <= (window.innerHeight + 100 || document.documentElement.clientHeight + 100) &&
          rect.right <= (window.innerWidth || document.documentElement.clientWidth)
      );
  }

/**
 * Ajoute une classe au header lors du scroll
 */
function setHeaderClassOnScroll() {
    let header = document.querySelector('.header-wrapper');
    let fullHeader = document.querySelector('.full-header');

    if (fullHeader && fullHeader.length > 0)
        return;


    if (header == null)
        return;

    let headerHeight = header.offsetHeight;
    let scrollTop = window.scrollY;
    let body = document.querySelector('.body');

    if (scrollTop > headerHeight) {
        header.classList.add('scrolled');
        body.classList.add('header-scrolled');
    } else {
        header.classList.remove('scrolled');
        body.classList.remove('header-scrolled');
    }

}

function lettering(selector) {
    var elements = document.querySelectorAll(selector);
    Array.from(elements).forEach(function(element) {
      var text = element.innerText;
      var letters = text.split("");
      element.innerHTML = "";
      letters.forEach(function(letter) {
        var span = document.createElement("span");
        span.textContent = letter;
        element.appendChild(span);
      });
    });
  }
  
  function animation() {

    let el = document.querySelector('.home-reassurance-wrapper');
    if (isElementInViewport(el) && !el.classList.contains('activated'))
    {

      el.classList.add('activated');

      var title1 = new TimelineMax();
      title1.staggerFromTo(".txt-anim span", 0.5, 
        {ease: Back.easeOut.config(1.7), opacity: 0, bottom: -80},
        {ease: Back.easeOut.config(1.7), opacity: 1, bottom: 0}, 0.05
      );
    }
  }