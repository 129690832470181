class AsideForm {
  constructor(form) {
    this.form = form;
    this.initNavigation();

    this.trigger = document.querySelector(".home-contact-aside-mobile-trigger");
    this.wrapper = document.querySelector(".home-contact-aside-wrapper");
    if (this.trigger && this.wrapper) {
      this.initPopupEventForMobile();
    }
  }

  //#region Navigation

  initNavigation() {
    let ref = this;

    let prevBtns = this.form.querySelectorAll("[data-prev]");
    prevBtns.forEach((prevBtn) => {
      prevBtn.addEventListener("click", function () {
        let stepNumber = prevBtn.getAttribute("data-prev");
        ref.setCurrentStep(stepNumber);
      });
    });

    let nextBtns = this.form.querySelectorAll("[data-next]");
    nextBtns.forEach((nextBtn) => {
      nextBtn.addEventListener("click", function () {
        let stepNumber = nextBtn.getAttribute("data-next");
        ref.setCurrentStep(stepNumber);
        ref.changeWrapperWidth();
      });
    });
  }

  getStep(stepNumber) {
    return this.form.querySelector('[data-step="' + stepNumber + '"');
  }

  getCurrentStep() {
    return this.form.querySelector(".active");
  }

  setCurrentStep(stepNumber) {
    let oldStep = this.getCurrentStep();
    oldStep.classList.remove("active");

    let newStep = this.getStep(stepNumber);
    newStep.classList.add("active");
  }

  changeWrapperWidth() {
    let wrapper = document.querySelector(".home-contact-aside-wrapper");
    let stepNumber = this.getCurrentStep().getAttribute("data-step");

    if (wrapper && stepNumber && stepNumber == 3) {
      wrapper.classList.add("large");
    }
  }

  //#endregion

  //#region Popup

  initPopupEventForMobile() {
    let ref = this;

    this.trigger.addEventListener("click", function () {
      ref.openPopup();
    });

    let cf7Form = this.form.closest(".wpcf7");

    if (cf7Form) {
      cf7Form.addEventListener('wpcf7mailsent', function () {
          ref.closePopup();
        }, false);
    }
  }

  /**
   * Ouvre la popup
   */
  openPopup() {
    if (!this.wrapper.classList.contains("on")) {
      this.wrapper.classList.add("on");
      this.addCloseButton();
      this.hideTrigger();
    }
  }

  /**
   * Ferme la popup
   */
  closePopup() {
    if (this.wrapper.classList.contains("on")) {
      this.wrapper.classList.remove("on");
      this.showTrigger();
    }
  }

  /**
   * Ajoute le bouton de fermeture de la popup
   */
  addCloseButton() {
    if (!this.closePopupTrigger) {
      let trigger = document.createElement("button");
      trigger.classList.add("home-contact-aside-wrapper-close");

      let icon = document.createElement("span");
      icon.classList.add("icon-croix");

      trigger.appendChild(icon);
      this.wrapper.appendChild(trigger);

      this.closePopupTrigger = trigger;
    }

    let ref = this;

    this.closePopupTrigger.addEventListener("click", function () {
      ref.closePopup();
    });
  }

  /**
   * Affiche le bouton déclencheur de la popup
   */
  showTrigger() {
    if (this.trigger.classList.contains("hidden")) {
      this.trigger.classList.remove("hidden");
    }
  }

  /**
   * Masque le bouton déclencheur de la popup
   */
  hideTrigger() {
    if (!this.trigger.classList.contains("hidden")) {
      this.trigger.classList.add("hidden");
    }
  }
  //#endregion
}

!(function () {
  document.addEventListener("DOMContentLoaded", function () {
    let asideForms = document.querySelectorAll('[data-form-type="step-form"]');

    if (asideForms && asideForms.length > 0) {
      asideForms.forEach((asideForm) => {
        new AsideForm(asideForm);
      });
    }
  });
})();
