class VideoHome {
  constructor(video) {
    if (!video) {
      console.error("aucune vidéo n'a été trouvée");
      return;
    }

    this.video = video;
    this.delayBeforeReplay = 3000;

    this.video.addEventListener('ended', () => {
        setTimeout(() => {
            this.replay();
        }, this.delayBeforeReplay);
    });

    this.autoplay();
  }

  //Lecture automatique de la vidéo
  autoplay() {
    this.mute();
    this.video.play().catch(function (error) {
      console.error("La lecture automatique a échoué :", error.message);
    });
  }

  //Mute le son
  mute() {
    this.video.muted = true;
  }

  // Réinitialiser la vidéo et la relire
  replay() {
    this.video.currentTime = 0;
    this.autoplay();
  }
}

document.addEventListener("DOMContentLoaded", function () {
  const videos = document.querySelectorAll("[data-video-home]");

  if (videos && videos.length > 0) {
    videos.forEach((video) => {
      new VideoHome(video);
    });
  }
});
